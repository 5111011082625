import React, { Fragment } from "react";
import axios from "axios";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.classNames = (...classes) => {
            return classes.filter(Boolean).join(" ");
        }

        this.navigation = [
            { name: "Support", href: "https://beta.caards.me/support" },
            { name: "Partners", href: "https://beta.caards.me/partners" },
        ];
    }

    async checkToken() {
        try {
            const res = await axios.get((process.env.REACT_APP_API || "https://api.caards.me") + "/utils/validate", {
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (!res.data.valid) {
                localStorage.removeItem("token", undefined);
                if (this.protectedPages.includes(window.location.pathname))
                    window.location.pathname = "/";
            }

            if (!localStorage.getItem("username") || !localStorage.getItem("id")) {
                const res1 = await axios.get((process.env.REACT_APP_API || "https://api.caards.me") + "/user/get/@me", {
                    headers: {
                        "Authorization": localStorage.getItem("token")
                    }
                });

                localStorage.setItem("username", res1.data.username);
                localStorage.setItem("id", res1.data.id);
            }
        } catch (err) {
            this.setState({ loading: false });
            if (err?.response?.data?.code === 403) {
                this.setState({ ban: true });
                document.getElementById("page").style.display = "none";
                document.getElementById("proxy-tor").style.display = "block";
            }
            console.error(err);
        }
    }

    run(cb) {
        window.onmessage = (e) => {
            if (e.origin !== "https://beta.caards.me") return;

            localStorage.setItem("token", e.data);
            cb();
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem("token")) {
            document.getElementsByTagName("iframe")[0].contentWindow.postMessage('{"key": "token"}', "https://beta.caards.me");
            this.run(async () => {
                await this.checkToken();
                return this.setState({ loading: false });
            });
        }

        this.setState({ loading: false });
    }

    render() {
        return (
            <Disclosure as="nav" className="pt-4">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                            <div className="relative flex items-center justify-between h-16">
                                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md M01 HB01">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link
                                            to="/"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mt-2" width="32" height="32" viewBox="0 0 32 32" fill="#90a7c4">
                                                <path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="Header-Buttons sm:ml-6">
                                        <div className="flex space-x-4">
                                            <a
                                                href="https://caards.me/"
                                                className={this.classNames(
                                                    "M01 HB01",
                                                    "px-3 py-2 rounded-md text-sm font-medium"
                                                )}
                                            >
                                                Home
                                            </a>
                                            {this.navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    onClick={item.onClick}
                                                    className={this.classNames(
                                                        "M01 HB01",
                                                        "px-3 py-2 rounded-md text-sm font-medium"
                                                    )}
                                                    aria-current={item.current ? "page" : undefined}
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                    {localStorage.getItem("token") ? (
                                        <>
                                            {/* Profile dropdown */}
                                            <Menu as="div" className="ml-3 relative">
                                                {({ open }) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-700">
                                                                <span className="sr-only">Open user menu</span>
                                                                <img
                                                                    className="h-8 w-8 rounded-full"
                                                                    src={`https://api.caards.me/media/avatar/${localStorage.getItem("id")}`}
                                                                    alt=""
                                                                />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="z-50	origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none B01"
                                                            >
                                                                <Menu.Item>
                                                                    <a
                                                                        href={"https://beta.caards.me/u/" + localStorage.getItem("username")}
                                                                        className="block px-4 py-2 text-sm M01 B01 HB01"
                                                                    >
                                                                        Your Profile
                                                                    </a>
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    <a
                                                                        href="https://beta.caards.me/feed"
                                                                        className="block px-4 py-2 text-sm M01 B01 HB01"
                                                                    >
                                                                        Feed
                                                                    </a>
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    <a
                                                                        href="https://beta.caards.me/analytics"
                                                                        className="block px-4 py-2 text-sm M01 B01 HB01"
                                                                    >
                                                                        Analytics
                                                                    </a>
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    <a
                                                                        href="https://beta.caards.me/me/settings"
                                                                        className="block px-4 py-2 text-sm M01 B01 HB01"
                                                                    >
                                                                        Settings
                                                                    </a>
                                                                </Menu.Item>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            <a
                                                href="https://beta.caards.me/signin"
                                                className={this.classNames(
                                                    "M01 HB01",
                                                    "px-3 py-2 rounded-md text-sm font-medium mr-2"
                                                )}
                                            >
                                                Signin
                                            </a>
                                            <a
                                                href="https://beta.caards.me/signup"
                                                className={this.classNames(
                                                    "M01 B01 HB01",
                                                    "px-3 py-2 rounded-md text-sm font-medium"
                                                )}
                                            >
                                                Signup
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Disclosure.Panel className="sm:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                <a
                                    href="https://beta.caards.me/"
                                    className={this.classNames(
                                        "M01 HB01",
                                        "block px-3 py-2 rounded-md text-base font-medium"
                                    )}
                                >
                                    Home
                                </a>
                                {this.navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={this.classNames(
                                            "M01 HB01",
                                            "block px-3 py-2 rounded-md text-base font-medium"
                                        )}
                                        aria-current={item.current ? "page" : undefined}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        );
    }
}