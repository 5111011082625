import React, { useState, useEffect } from "react";
import axios from "axios";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import toc from "remark-toc";
import highlight from "rehype-highlight"

import Header from "./Components/Header";

import 'highlight.js/styles/github.css';

function App(props) {
  let path = "https://raw.githubusercontent.com/caards/knowledgebase/main/" + (props.location.pathname === "/" ? "index.md" : props.location.pathname + ".md");
  const [MD, setMD] = useState("# Loading");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(path);
        setMD(res.data);
      } catch {
        setMD("# 404 - Page not found");
      }
    };
    getData();
  }, [MD, path])

  return (<>
    <Header />
    
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 M01 pt-4 pb-10 Content">
      <ReactMarkdown
        remarkPlugins={[gfm, toc]}
        rehypePlugins={[highlight]}
      >
        {MD}
      </ReactMarkdown>

    </div>
  </>);
}

export default App;
